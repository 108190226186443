var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.id<0)?_c('v-btn',{attrs:{"color":"success","disabled":!_vm.$can('create','Activity')},on:{"click":function($event){_vm.showDialog=true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v(" Nueva ")],1):_vm._e(),(_vm.id>0)?_c('v-icon',{staticClass:"px-1",attrs:{"small":"","disabled":!_vm.$can('update','Activity')},on:{"click":function($event){_vm.showDialog=true}}},[_vm._v(" fas fa-pencil ")]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{},[_vm._v(" "+_vm._s(_vm.item.id>-1?'Actualizar Actividad':'Crear Actividad')+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.item.id>-1?'Actualice los datos de la Actividad':'Ingrese de la Actividad')+" ")]),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fecha de Ingreso","type":"date","dense":"","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Tipo de Actividad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('tipo-activity-selection',{attrs:{"errors":errors,"loading":_vm.loading,"filtro":false},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre del cliente","placeholder":"Ingrese el nombre del cliente","dense":"","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.customer_name),callback:function ($$v) {_vm.customer_name=$$v},expression:"customer_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"¿Quien lo atendió?","placeholder":"Ingrese el nombre del contacto","dense":"","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})]}}],null,true)}),_c('v-textarea',{attrs:{"label":"Observaciones","placeholder":"Ingrese observaciones adicionales","rows":"3","dense":"","outlined":"","loading":_vm.loading},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),(_vm.id>-1)?_c('v-btn',{staticClass:"mb-4",attrs:{"color":"error","outlined":"","disabled":_vm.loading},on:{"click":function (){_vm.showConfirmDelete=true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-trash")]),_vm._v("Eliminar")],1):_vm._e(),_c('div',{staticClass:"text-center mt-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"type":"submit","color":"success","loading":_vm.loading}},[_c('v-icon',[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1)],1)],1)]}}])})],1)],1)],1),(_vm.item)?_c('confirm',{attrs:{"message":"¿Confirma eliminar el registro? Esta operación no se puede deshacer."},on:{"ok":function($event){return _vm.deleteItem()},"cancel":function (){_vm.showConfirmDelete=false;_vm.itemToDelete=''}},model:{value:(_vm.showConfirmDelete),callback:function ($$v) {_vm.showConfirmDelete=$$v},expression:"showConfirmDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <v-btn color="success" @click="showDialog=true" v-if="id<0" :disabled="!$can('create','Activity')">
      <v-icon class="mr-2">fas fa-plus</v-icon>
      Nueva
    </v-btn>
    <v-icon 
      class="px-1" 
      small 
      v-if="id>0" :disabled="!$can('update','Activity')"
      @click="showDialog=true"
      >
      fas fa-pencil
    </v-icon>
    <v-dialog max-width="400" v-model="showDialog">
      <v-card>
        <v-card-title
        class=""
        >
        {{item.id>-1?'Actualizar Actividad':'Crear Actividad'}}
      </v-card-title>
      <v-card-subtitle>
        {{item.id>-1?'Actualice los datos de la Actividad':'Ingrese de la Actividad'}}
      </v-card-subtitle>
      <v-card-text>
          <ValidationObserver v-slot="{handleSubmit}">
            <v-form @submit.prevent="handleSubmit(save)">
              <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                label='Fecha de Ingreso'
                type="date"
                v-model='date'
                dense
                outlined
                :error-messages="errors"
                :loading="loading"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Tipo de Actividad" rules="required" v-slot="{ errors }">
                <tipo-activity-selection
                v-model='tipo'
                :errors="errors"
                :loading="loading"
                :filtro="false"
                />
              </ValidationProvider>
              <ValidationProvider name="Cliente" rules="required" v-slot="{ errors }">
                <v-text-field
                label='Nombre del cliente'
                v-model='customer_name'
                placeholder="Ingrese el nombre del cliente"
                dense
                outlined
                :error-messages="errors"
                :loading="loading"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                label='¿Quien lo atendió?'
                v-model='contact'
                placeholder="Ingrese el nombre del contacto"
                dense
                outlined
                :error-messages="errors"
                :loading="loading"
                ></v-text-field>
              </ValidationProvider>
              <v-textarea
              label='Observaciones'
              placeholder="Ingrese observaciones adicionales"
              v-model='notes'
              rows="3"
              dense
              outlined
              :loading="loading"
              ></v-textarea>
              <v-btn v-if="id>-1" color="error" class="mb-4" @click="()=>{showConfirmDelete=true}" outlined :disabled="loading"><v-icon class="mr-2">fas fa-trash</v-icon>Eliminar</v-btn>
              
                <div class="text-center mt-4">
                  <v-btn text @click="cancel()" class="mr-2" :disabled="loading">Cancelar</v-btn>
                  <v-btn type="submit" color="success" :loading="loading"><v-icon >fas fa-sd-card</v-icon>Guardar</v-btn>
                </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>

      </v-card>
    </v-dialog>
    <confirm
      v-if="item"
      v-model="showConfirmDelete" 
      :message="`¿Confirma eliminar el registro? Esta operación no se puede deshacer.`"
      @ok="deleteItem()"
      @cancel="()=>{showConfirmDelete=false;itemToDelete=''}"/>
  </v-container>
</template>
<script>

import {getActivity,updateActivity,deleteActivity,createActivity} from '@/api/salesNotes'
import notifications from '@/utils/notifications'
import TipoActivitySelection from '@/components/sales/TipoActivitySelection'

export default {
  name:'ActivityCreateUpdate',
  components:{
    TipoActivitySelection
  },
  props:{
    id:{
      type:Number,
      default:-1
    },
    deleting:{
      type:Boolean,
      default:false
    },
    item:{
      type:Object,
      default:()=>({id:-1})
    }
  },
  data() {
    return {
      item:'',
      loading:false,
      showConfirmDelete:false,
      showDialog:false,
      date:'',
      tipo:'',
      contact:'',
      customer_name:'',
      notes:'',
    }
  },
  methods:{
    async save(){
      this.loading=true
      const data={
          date:this.date,
          tipo:this.tipo,
          contact:this.contact,
          notes:this.notes,
          customer_name:this.customer_name
        }
      if(this.id>-1){
        await updateActivity(this.id,data)
        .then(async (res)=>{
          notifications.showSuccess('Éxito','Actividad guardada correctamente')
          this.item=""
          this.$emit('updated')
          this.cancel()
        })
        .catch(err=>{
          notifications.showError('Error', err)
        })
        this.loading=false
      }
      else{
        await createActivity(data)
        .then(async (res)=>{
          notifications.showSuccess('Éxito','Actividad creada correctamente')
          this.date=""
          this.tipo=""
          this.contact=""
          this.notes=""
          this.customer_name=""
          this.$emit('updated')
          this.cancel()
        })
        .catch(err=>{
          notifications.showError('Error', err)
        })
        this.loading=false
      }
      
    },
    deleteItem(){
      this.loading=true
      deleteActivity(this.id)
      .then(async res=>{
        notifications.showSuccess('Éxito','Registro eliminado correctamente')
        this.showConfirmDelete=false
        
        this.$emit('close')
        this.$emit('updated')
        this.cancel()
      })
      .catch(err=>{
        notifications.showError('Error',err)
      })
      this.loading=false
    },
    cancel(){
      
      this.loading=false
      this.showConfirmDelete=false
      this.showDialog=false
    }
  },
  mounted(){
    if(this.item.id>-1){
      this.id=this.item.id
      this.date=this.item.date
      this.tipo=this.item.tipo
      this.contact=this.item.contact
      this.notes=this.item.notes
      this.customer_name=this.item.customer_name
    }
  }

}
</script>

<style>

</style>